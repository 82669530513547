.BenefitAmount-title {
  font-size: 24px;
  color: @c24-color-black;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.BenefitAmount-helpLink-container {
  display: flex;
  justify-content: flex-end;
}

.BenefitAmount-helpLink {
  font-family: Arial;
  font-size: 16px;
  color: @c24-color-dark-blue;
  line-height: 16px;
  text-align: right;
  margin-top: 10px;
}