@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.ThankyouPage {
  font-size: 14px;
  background: @c24-color-background-grey;

  > div {
    padding: 20px 15px;
    background: @c24-color-white;
  }

  & > .C24Points-mainWrapper {
    margin: -25px -10px 10px;
  }

  .ThankyouPage-C24Tag {
    display: block;
  }

  .ThankyouAnotherProducts-container {
    padding-top: 0px;
  }
}

.ThankyouPage-otherProductsContainer {
  padding-bottom: 40px;
  margin-top: -10px;
}

.ThankyouPage-otherProductsButton {
  background: @c24-color-background-grey;
  font-weight: normal;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0, 1);
  color: @c24-color-malaga-grey;

  &:active, &:hover {
    background: @c24-color-background-grey;
    border: 1px solid #ccc;
    color: @c24-color-light-grey;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0, 1);
  }
}
