@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.OnboardingSummarySection-header {
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 24px;
    line-height: 28px;
    color: @c24-color-dark-blue;
  }

  svg {
    fill: @c24-color-dark-blue;
    margin-top: 2px;
    width: 35px;
    height: 35px;
  }
}

.OnboardingSummarySection-headerBorder {
  border-top: 1px solid @c24-color-malaga-grey;
  margin-top: 16px;
}