.ThankyouErrorPage {
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
  height: 100%;
}

.ThankyouErrorPage-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: @c24-color-white;
  height: 100%;
}

.ThankyouErrorPage-headline {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.ThankyouErrorPage-crossSvg {
  width: 28px;
  height: 22px;
  margin-right: 10px;
  fill: @c24-color-checked;
}

.ThankyouErrorPage-headlineText {
  color: @c24-color-black;
  font-size: 20px;
}

.ThankyouErrorPage-infoMessage {
  font-size: 14px;
  color: @c24-color-black;
}

.ThankyouErrorPage-text {
  font-size: 16px;
  color: @c24-color-black;
  margin-top: 30px;
}

.ThankyouErrorPage-phoneNumberContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
}

.ThankyouErrorPage-phoneSvg {
  fill: @c24-color-icon-default;
  height: 27px;
  width: 27px;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 2px;
}

.ThankyouErrorPage-phoneNumber {
  color: @c24-color-dark-blue;
  font-size: 20px;
}

.ThankyouErrorPage-workingTimes {
  color: @c24-color-black;
  font-size: 16px;
}

.ThankyouErrorPage-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.ThankyouErrorPage-backLink {
  font-size: 16px;
  color: @c24-color-black;
}
