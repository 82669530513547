@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.CashbackErrorMessage {
  margin: 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.CashbackErrorMessage-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 16px;
  color: @c24-color-black;
  font-weight: 400;
  line-height: 30px;

  .CashbackErrorMessage-header {
    font-size: 20px;
  }

  .CashbackErrorMessage-body {
    font-size: 16px;
  }

  .CashbackErrorMessage-circle-svg {
    height: 29px;
    fill: @c24-color-nobel-grey;
    margin-top: 6px;
  }

  .CashbackErrorMessage-phone-svg {
    height: 32px;
    fill: @c24-color-dark-blue;
    margin-top: 6px;
  }
}


