@import 'node_modules/bu.components-common-styles/dist/styles/variables/browser-hacks.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/browser-hacks.less';

.EfeedbackRatings-container {
  display: flex;
  align-items: center;

  &.is-comparison {
    @media @small-handy {
      display: block;
    }
  }
}

.EfeedbackRatings-numberOfRatings {
  display: flex;
  justify-content: center;
  color: @c24-color-black;
  margin-left: 5px;
  font-size: 14px;

  @media @is-Safari {
    margin-top: -5px;
  }
}

.EfeedbackRatings-container.is-comparison > .EfeedbackRatings-numberOfRatings {
  @media @small-handy {
    margin-left: 0;
  }
}
