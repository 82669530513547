@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.ThankyouTariffInfo-tariffInfoRow {
  display: flex;
}

.ThankyouTariffInfo-tariffInfoLabel {
  min-width: 125px;
  font-size: 16px;
}

.ThankyouTariffInfo-tariffInfoDescription {
  font-weight: 700;
  font-size: 16px;
}
