.HelpText-questionMark {
  position: absolute;
  right: 17px;
  top: 17px;
  width: 20px;
  height: 20px;
  fill: @c24-color-grey;

  &:hover {
    fill: @c24-color-icon-hover;
  }

  &.is-active {
    fill: @c24-color-icon-active;
  }
}
