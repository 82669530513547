@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.ComparisonPage-headerButtonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  color: @c24-color-dark-blue;
  background-color: @c24-color-white;
  font-size: 13px;
  align-items: center;
}

.ComparisonPage-backButtonArrow {
  height: 6px;
  width: 11px;
  margin-right: 5px;
  transform: rotate(-90deg);
  fill: @c24-color-dark-blue;
}

.ComparisonPage-backButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ComparisonPage {
  background: @c24-color-background-grey;
}
