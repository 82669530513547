.ResultPage-FilterOptions {
  margin: 27px 10px 268px 10px;

  &.RadioButton-caption {
    font-size: 16px !important;
  }
}

.ResultFiltersOverlay-label {
  font-size: 18px;
  color: @c24-color-malaga-grey;
  line-height: 18px;
}

.ResultFiltersOverlay-row {
  position: relative;
}

.ResultFiltersOverlay-check24TariffGradeContainer {
  width: 90%;
}

.ResultFiltersOverlay-filterChip {
  min-height: 40px;
  padding: 0 10px;
  max-width: 290px;
  box-shadow: @c24-box-filter-shadow;

  @media @small-handy {
    max-width: 180px;
  }
  @media @middle-handy {
    max-width: 245px;
  }
}

.ResultFiltersOverlay-checkBoxQuestionMark {
  top: 13px;
}

.ResultFiltersOverlay-dynamicPremiumFilterContainer {
  display: flex;
  width: 90%;
  flex-wrap: wrap;

  .FirstViewFilterChips {
    margin-top: 10px;
    white-space: nowrap;
  }
}

.ResultFiltersOverlay-checkBoxQuestionMark {
  top: 20px;
}

.ResultFiltersOverlay-dynamicPremiumFilter, .ResultFiltersOverlay-check24TariffGrade {
  .ResultFiltersOverlay-checkBoxQuestionMark {
    top: 38px;
  }
}

.ResultFiltersOverlay-dynamicPremiumFilter {
 margin-top: 40px;
 margin-bottom: 40px;
}
