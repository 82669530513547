@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.Grade-mobile {
  position: relative;
  float: right;
}

.Grade-icon-mobile {
  height: 59px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: 50px 59px;
  background-image: url(/public/grade_grey.svg);

  &.is-golden {
    background-image: url(/public/grade_gold.svg);
  }
}

.Grade-calculatedValue-mobile {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: @c24-color-black;
}

.Grade-subText-mobile {
  position: absolute;
  top: 12px;
  width: 100%;
  text-align: center;
  font-size: 8px;
  color: @c24-color-black;
}

.Grade-gradeText-mobile {
  position: absolute;
  font-size: 10px;
  top: 45px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: @c24-color-white;
  font-weight: bold;

  &.is-longText {
    top: 47px;
    font-size: 7px;
  }
}
