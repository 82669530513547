.basicTransition-enter {
  opacity: 0.01;
}

.basicTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.basicTransition-leave {
  opacity: 1;
}

.basicTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
