.FamilyStatus-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 14px;
}

.FamilyStatus-familyStatuses {
  margin-top: 16px;
}
