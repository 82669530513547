@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/browser-hacks.less';

.EfeedbackRatingStars {
  width: 75px;
  position: relative;
}

.EfeedbackRatingStars-starsContainer {
  display: inline-block;
  font-size: 12px;

  &.is-overlay {
    position: absolute;
    white-space: nowrap;
    overflow-x: hidden;
    left: 0;
    top: 0;
  }
}

.EfeedbackRatingStars-star {
  height: 15px;
  width: 15px;
  fill: @c24-color-light-grey;

  &.is-overlay {
    fill: @c24-color-yellow;
  }
}
