.AppointmentDeletedCustomers-container {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  min-height: calc(100vh - 215px);
}

.AppointmentDeletedCustomers-title {
  display: flex;
  align-items: center;

  svg {
    width: 27px;
    height: 27px;
  }

  div {
    color: @c24-color-black;
    height: 20px;
    font-size: 20px;
    margin-left: 10px;
    line-height: 19px;
    margin-bottom: 0px;
    margin-top: 1px;
  }
}

.AppointmentDeletedCustomers-informative-text {
  color: @c24-color-black;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 3px;
  margin-right: 3px;
}

.AppointmentDeletedCustomers-button {
  background-color: @c24-color-button-background;
  box-shadow: @c24-box-primary-button-shadow;
  svg {
    fill: @c24-color-white;
    margin-bottom: -2px;
  }

  .AppointmentDeletedCustomers-svg-email {
    width: 24px;
    height: 25px;
    fill: @c24-color-white;
    margin-bottom: -7px;
  }
}

.AppointmentDeletedCustomers-text-hour {
  color: @c24-color-black;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
