.ResultTariffListGfBanner-modalContainer {
  padding: 5px;
}

.ResultTariffListGfBanner-container {
  background-color: @c24-color-white;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  display: block;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.12);
}

.ResultTariffListGfBanner-bannerBody {
  background-color: @c24-color-orange;
  height: 25px;
  display: inline-block;
  vertical-align: top;
  width: 240px;

  &:after {
    content: "";
    position: relative;
    left: calc(100% - 2px);
    top: -1px;
    border-left: 2px solid transparent;
    border-right: 13px solid transparent;
    border-top: 25px solid @c24-color-orange;
  }

  &.is-modal {
    border-top-left-radius: 10px;
    width: 260px;
    &:after {
      top: -2px;
    }
  }

  &.is-long-occupation {
    height: 50px;
    &:after {
      border-top: 50px solid @c24-color-orange;
    }
  }
}

.ResultTariffListGfBanner-bannerCorner {
  display: inline-block;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 25px solid @c24-color-orange;
  margin-left: -0.2px;

  &.is-long-occupation {
    border-top: 50px solid @c24-color-orange;
  }
}

.ResultTariffListGfBanner-bannerContainer {
  padding: 10px 0 10px 10px;
}

.ResultTariffListGfBanner-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}

.ResultTariffListGfBanner-text {
  font-size: 15px;
}

.ResultTariffListGfBanner-priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.ResultTariffListGfBanner-price {
  font-size: 24px;
  color: @c24-color-green;
  white-space: nowrap;
  margin-bottom: -8px;
}

.ResultTariffListGfBanner-priceText {
  font-size: 11px;
}

.ResultTariffListGfBanner-modalCloseContainer {
  display: flex;
  justify-content: flex-end;
}

.ResultTariffListGfBanner-modalClose {
  width: 16px;
  height: 16px;
  fill: @c24-color-close;
  position: absolute;
  top: 0;
}

.ResultTariffListGfBanner-modalHeader {
  font-size: 19px;
  color: @c24-color-dark-grey;
  margin-top: 10px;
}

.ResultTariffListGfBanner-modalText {
  font-size: 18px;
  color: @c24-color-dark-grey;
}

.ResultTariffListGfBanner-modalPriceContainer {
  margin-top: 20px;
}

.ResultTariffListGfBanner-modalPrice {
  font-size: 24px;
  color: @c24-color-green;
  display: flex;
  justify-content: flex-end;
  line-height: 25px;
}

.ResultTariffListGfBanner-gfUspList {
  margin-top: 20px;
}

.ResultTariffListGfBanner-modalPriceInterval {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}

.ResultTariffListGfBanner-gfUspContainer {
  display: flex;
  margin-top: 8px;
}

.ResultTariffListGfBanner-gfUspBullet {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}

.ResultTariffListGfBanner-gfUspText {
  font-size: 16px;
  color: @c24-color-dark-grey;
}

.ResultTariffListGfBanner-modalActionButtons {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.ResultTariffListGfBanner-modalButtonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ResultTariffListGfBanner-modalButton {
  &.back {
    margin-top: 10px;
    background-color: @c24-color-background-grey;
    border: 1px solid @c24-color-divider-grey;
    color: @c24-color-malaga-grey;

    &:active, &:active:focus, &:hover, &:focus {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
    }
  }
}

.ResultTariffListGfBanner-modalButtonText {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
