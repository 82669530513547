
.ThankyouAppointmentsCalendarInfo-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.ThankyouAppointmentsCalendarInfo-icon {
  width: 14px;
  height: 14px;
  min-width: 14px;
  fill: @c24-color-malaga-grey;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 1px;
}

.ThankyouAppointmentsCalendarInfo-text {
  font-size: 13px;
}
