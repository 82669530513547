.ValidationBar-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  z-index: 1;
  transition-property: width;
  transition-duration: .25s;

  &.is-error {
    background-color: @c24-color-is-error;
    width: 100%;
  }

  &.is-valid {
    background-color: @c24-color-green;
    width: 100%;
  }
}

.ValidationBar {
  position: relative;
}