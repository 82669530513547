@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ResultSearchCriteriaHeader {
  color: @c24-color-black;
  font-size: 14px;
  padding: 5px;
  background: @c24-color-going-yellow;
}

.ResultSearchCriteriaHeader-CriteriaText {
  background: @c24-color-white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
}

.ResultSearchCriteriaHeader-Content {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80vw;
  display: block;
  overflow: hidden;
  margin: auto 0;
}

.ResultSearchCriteriaHeader-onboardingLinkIcon {
  fill: @c24-color-dark-blue;
  width: 35px;
  height: 35px;
  align-self: center;
}
