@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.PersonalDataPage {
  .Page;
}

.PersonalDataPage-headlineBox {
  padding: 10px 15px 13px;
}

.PersonalDataPage-section {
  margin-top: 11px;
}

.PersonalDataPage-hint {
  display: flex;
  margin-top: 10px;
  font-size: 16px;
  color: @c24-color-black;
  line-height: 18px;
}

.PersonalDataPage-checkHint {
  width: 25px;
  min-width: 25px;
  height: 20px;
  margin-right: 10px;
  fill: @c24-color-green;
}

.PersonalDataPage-headline {
  color: @c24-color-malaga-grey;
  font-size: 20px;
  margin-bottom: 24px;
}

.PersonalDataPage-ctaSection {
  display: flex;
  margin: 0 15px 40px;
}

.PersonalDataPage-errorSection {
  color: @c24-color-red;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 5px;
}

.PersonalDataPage-genericError {
  font-size: 16px;
  margin-top: 7px;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  color: @c24-color-red;
}

.PersonalDataPage-inquiryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PersonalDataPage-genericErrorIcon {
  > svg {
    width: 17px;
    height: 17px;
    margin-bottom: -2px;
    margin-right: 6px;
  }
}

.PersonalDataPage-inquiryButtonHint {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  color: @c24-color-green;
}

.PersonalDataPage-checkIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: @c24-color-green;
}

.PersonalDataPage-inquiryButton {
  margin-bottom: 4px;
}

.PersonalDataPage-agreementSection {
  margin-top: 40px;
}

.PersonalDataPage-backButton {
  font-family: Arial, serif;
  font-size: 13px;
  color: @c24-color-dark-blue;
  margin-top: 20px;
}

.PersonalDataPage-backButtonArrow {
  height: 6px;
  width: 11px;
  transform: rotate(-90deg);
  fill: @c24-color-dark-blue;
}
