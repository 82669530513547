@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.OnboardingPersonalDataSection-row {
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  .OnboardingPersonalDataSection-occupationOverlay {
    background: transparent;
    box-shadow: none;
    padding: 10px 10px 0 10px;
    border: none;
    width: 100%;
    padding: 0px;
    margin-top: 30px;
  }
}

.OnboardingPersonalDataSection-space {
  margin-bottom:24px;
}

.OnboardingPersonalDataSection-birthdayRow {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.OnboardingPersonalDataSection-label {
  font-size: 13px;
  color: @c24-color-malaga-grey;
  padding-top: 9px;
  padding-left: 9px;
}


.OnboardingPersonalDataSection-recalcHintIcon {
  width: 40px;
  height: 40px;
  fill: @c24-color-dark-blue;
  margin-right: 15px;
  flex: 0 0 50px;
  display: flex;
}

.OnboardingPersonalDataSection-recalcHintHeadlineText {
  font-weight: bold;
}

.OnboardingPersonalDataSection-recalcHint {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 14px;
  border: 1px solid @c24-color-black;
  padding: 15px 15px;
  background-color: @c24-color-white;
  position: relative;
}

.OnboardingPersonalDataSection-recalcHint:after, .OnboardingPersonalDataSection-recalcHint:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  content: " ";
  border: solid transparent;
}

.OnboardingPersonalDataSection-recalcHint:after {
  border: 15px solid transparent;
  border-bottom: @c24-border-recalc-hint-after;
  margin-left: 1px;
}

.OnboardingPersonalDataSection-recalcHint:before {
  border: 16px solid transparent;
  border-bottom: 16px solid @c24-color-black;
}

.OnboardingPersonalDataSection-recalcHintClose {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: @c24-color-nobel-grey;
}

.OnboardingPersonalDataSection-inputWrapper {
  background: @c24-color-white;
  padding-bottom: 6px;
}

.OnboardingPersonalDataSection-headlineHelpText {
  top: 4px;
  right: 10px;
  height: 20px;
  width: 20px;
}

.OnboardingPersonalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}

.OnboardingPersonalDataSection-OccupationInput {
  .InputAutoSuggest-closeWrapper {
    top: 30px;
    right: 25px;
  }
}