.NoTariffsFound {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoTariffsFound-header {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: @c24-color-dark-grey;
}

.NoTariffsFound-text {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.NoTariffsFound-cta {
  margin-top: 20px;
  margin-bottom: 10px;
}

.NoTariffsFound-hint {
  display: flex;
  align-items: center;
  color: @c24-color-green;
  margin-top: 10px;
}

.NoTariffsFound-checkmark {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.NoTariffsFound-iconContainer {
  display: flex;
  align-items: center;
}

.NoTariffsFound-icon {
  width: 180px;
}
