.ResultTariffList {
  padding: 8px 10px 0;
  overflow: hidden;
}

.ResultTariffList-singleRow {
  position: relative;
  margin-bottom: 14px;
}

.ResultTariffList-singleRow:last-child {
  margin-bottom: 0;
}
