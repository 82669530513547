@import "node_modules/bu.components-common-styles/dist/styles/variables/colors";

.VersicherungPlusSignup-option {
  font-size: 16px;
}

.VersicherungPlusSignup-title {
  font-size: 18px;
}

.VersicherungPlusSignup-pointsText {
  font-size: 14px;
  color: @c24-color-malaga-grey;
}