@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.OnboardingAdditionalData-nextButton {
  height: 50px;
  margin-bottom: 20px;
}

.OnboardingAdditionalData-content {
  display: block;
  padding: 15px 15px 0;
  position: relative;
  border-top: @c24-border-light-grey;

  .OnboardingAdditionalData-campaignBanner {
    margin-top: 20px;
  }
}
