.OnboardingPage-container {
  background-color: @c24-color-white;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.OnboardingPage-ctaButton {
  margin-top: 24px;
}

.OnboardingPage-toResultsButton {
  margin-top: 16px;
}

.OnboardingPage-BackButton {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: -5px;
}

.OnboardingPage-trustLogos {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;
}

.OnboardingPage-skipToResultButton {
  margin-top: 16px;
}