.Unsubscribe-container {
  margin: 17px 9px 0 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Unsubscribe-headline {
  display: flex;
  align-items: center;
  margin: 15px 0 25px 0;
}

.Unsubscribe-checkSvg {
  width: 25px;
  height: 20px;
  margin-right: 15px;
  fill: @c24-color-dark-green;
}

.Unsubscribe-headlineText {
  font-size: 20px;
}

.Unsubscribe-text {
  font-size: 16px;
  margin-bottom: 20px;
  flex: 1;
}
