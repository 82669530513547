.InsuranceList-headline {
  font-weight: bold;
  font-size: 16px;
  color: @c24-color-headline;
}

.InsuranceList-insurances {
  padding: 15px;
}

.InsuranceList-subHeadline {
  padding-top: 10px;
  font-size: 16px;
}

.InsuranceList-link {
  display: flex;
  padding: 0;
  align-items: center;
  color: @c24-color-link;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}

.InsuranceList-dateOfValidity {
  color: @c24-color-nobel-grey;
}

.InsuranceList-linkPointer {
  transform: rotate(90deg);
  width: 17px;
  height: 9px;
  margin-right: 10px;
  fill: @c24-color-dark-blue;
}

.InsuranceList-separatorBar {
  margin-bottom: 10px;
  border-bottom: @c24-border-light-grey;
  border-width: 2px;
}

.InsuranceList-headlineInfo {
  color: @c24-color-black;
}

.InsuranceList-pdfIcon {
  fill: @c24-color-dark-blue;
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.InsuranceList-pdfLink {
  display: flex;
  align-items: self-end;
  margin: 10px 0;
}
