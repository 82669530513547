.InquiryButton-container {
  margin: 15px 10px 10px 10px;
}

.InquiryButton-hint {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  color: @c24-color-black;
}

.InquiryButton-checkIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: @c24-color-checked;
}
