.ThankyouAppointmentHeader-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
}

.ThankyouAppointmentHeader-icon {
  width: 25px;
  height: 20px;
  min-width: 25px;
  margin-top: 3px;
  fill: @c24-color-green;
}

.ThankyouAppointmentHeader-textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.ThankyouAppointmentHeader-text {
  font-size: 20px;
}

.ThankyouAppointmentHeader-subText {
  font-size: 14px;
}
