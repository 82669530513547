@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.InsuranceAndTariff-savingsTextPrice {
  color: @c24-color-dark-green;
}

.InsuranceAndTariff-logoAndNameContainer {
  width: 70%;
}

.InsuranceAndTariff-insuranceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InsuranceAndTariff-insuranceLogo {
  display: block;
  max-height: 35px;
  max-width: 55%;
}

.InsuranceAndTariff-savingsTextContainer {
  width: 80%;
  margin-left: 20%;
  margin-bottom: 5px;
}

.InsuranceAndTariff-savingsText {
  text-align: right;
  font-size: 14px;
}

.InsuranceAndTariff-tariffName {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  word-wrap: break-word;
}
