@import 'node_modules/bu.components-common-styles/dist/styles/variables/z-indexes';

.OverlayContainer{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0;
  background: #f4f4f4;
  top: 100vh;
  z-index: @c24-z-index-mobile-search-bar;
  transition: all .3s ease 0s;

  &.is-comparison {
    overflow-y: scroll !important;
  }

  &.is-scroll-enabled {
    @media screen and (max-width: 415px){
      overflow-y: scroll !important;
    }
  }
}

.OverlayTopBar {
  height: 45px;
  background: @c24-color-white;
  border-bottom: solid 1px @c24-color-light-grey;
  align-content: center;
  display: flex;
}

.OverlayCloseButton {
  height: 16px !important;
  width: 16px;
  margin: 15px 10px;
  fill: @c24-color-malaga-dark-blue;
}

.OverlayTitle {
  margin: auto -15% auto auto;
  text-align: center;
  font-size: 18px;
}

.OverlayResetButton {
  margin: auto 10px auto auto;
  font-size: 16px;
  color: @c24-color-dark-blue;

  &.is-default {
    color: @c24-color-grey;
  }
}

.OverlayContainer.is-opened {
  display: inherit;
  transition: all .5s ease 0s;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;

  .OverlayTopBar {
    top: 0;
    position: sticky;
    z-index: 1;
  }
}



