@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ResultPage {
  .Page;
  background: @c24-color-background-grey;
}

.ResultPage-insuranceListLink {
  margin-left: 228px;
}

.ResultPage-infoTariffNotes {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
}

.ResultPage-insuranceLink {
  padding-left: 10px;
  padding-right: 10px;
}

.ResultPage-savingsCampaignBanner {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
