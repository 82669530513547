@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.RecommendationHint {
  .RecommendationHintModal-dialog {
    max-height: none;

    @media @short-handy {
      top: 60% !important;
    }
    background-color: @c24-color-white;
  }
}

.RecommendationHintModal-row {
  max-width: 694px;
  margin-top: 10px;
}

.RecommendationHintModal-headline {
  color: @c24-color-black;
  font-size: 18px;
  margin-bottom: 16px;
  padding-right: 35px;
}

.RecommendationHintModal-ctaContainer {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.RecommendationHintModal-formInput {
  & > div > input {
    color: initial;
  }
}
