@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.PersonalLogin-iframe {
  display: block;
}

.PersonalLogin-separation-line {
  margin-bottom: 0;
  background: @c24-color-white;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 0;
  margin-top: 1px;
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    border-top: 1px solid @c24-color-light-grey
  }
}
