@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/backgrounds-mobile';

.ThankyouSteps {
  font-size: 16px;
  padding: 10px;
  background-color: @c24-color-white;
  margin-bottom: 10px;
}

.ThankyouSteps-headline {
  color: @c24-color-black;
  font-size: 20px;
}

.ThankyouSteps-numberSvg {
  height: 25px;
  width: 25px;
  margin-right: 5px;
  fill: @c24-color-malaga-grey;
}

.ThankyouSteps-item {
  margin-top: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ThankyouSteps-stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.ThankyouSteps-headerText {
  font-weight: 700;
}

.ThankyouSteps-stepText {
  margin-left: 30px;
}
