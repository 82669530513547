.SmokingPage {
  margin-top: 16px;

  & > div{
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }
}
.SmokingPage-title {
  font-size: 24px;
  color: @c24-color-black;
  line-height: 28px;
  margin-bottom: 11px
}

.SmokingPage-link {
  font-size: 12px;
  color: @c24-color-dark-blue;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
}

.SmokingPage-ctaButton {
  margin-top: 16px;
}
