@import "node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile";

.ComparisonStickyHeader {
  display: none;
  background: white;
  position: absolute;
  width: 100%;
}

.ComparisonPage-stickyContainer {
  .is-sticky {
    position: sticky;
    top: 0;
    display: flex;
    z-index: 100;
    background: white;

    .ComparisonStickyHeader {
      display: flex;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
}

.ComparisonStickyHeader-insuranceLogo {
  height: 35px;
  max-width: 100%;
  margin-bottom: 11px;
  margin-top: 10px;
}

.ComparisonStickyHeader-backdatedPriceContainer {
  margin-top: -15px;
}

.ComparisonStickyHeader-priceContainer,
.ComparisonStickyHeader-backdatedPriceContainer {
  display: block;
  text-align: center;
  min-width: 90px;
  line-height: 15px;
  padding-bottom: 5px;
}

.ComparisonStickyHeader-priceContainer {
  color: @c24-color-black;
}

.ComparisonStickyHeader-price {
  color: @c24-color-price;
  font-size: 20px;
}

.ComparisonStickyHeader-tariffBlock {
  .ComparisonSelectedTariffsHeader-tariffBlock;

  padding: 0 10px 0;

  &:last-of-type {
    border-right: none;
  }

  &.is-resultZero {
    background-color: @c24-color-light-blue;
  }
}

.ComparisonStickyHeader-imageContainer {
  display: table;
  margin: auto auto 5px;
}

.ComparisonStickyHeader-oldPrice {
  font-size: 11px;
  line-height: 11px;
  color: @c24-color-red;
  text-decoration: line-through;
  margin-bottom: 5px;
}
