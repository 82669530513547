.ComparisonHint {
  display: flex;
  background-color: #cae7a8;
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  color: @c24-color-dark-blue;
  box-shadow: @c24-border-box-shadow-tab;
}

.ComparisonHint-comparisonIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  fill: @c24-color-dark-blue;
}

.ComparisonHint-iconContainer {
  display: flex;
  align-items: center;
}
