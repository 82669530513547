@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.PersonalDataSection-container {
  display: block;
  padding: 0 15px;
}

.PersonalDataSection-row {
  position: relative;
  width: 100%;
  margin-right: 50px;
  margin-bottom: 14px;
}

.PersonalDataSection-label {
  height: 20px;
  font-size: 14px;
  width: 40%;
  color: @c24-color-malaga-grey;
}

.PersonalDataSection-labelSubtitle {
  font-size: 14px;
  color: @c24-color-malaga-grey;
  line-height: 16px;
  margin-top: 5px;
}

.PersonalDataSection-bestSso {
  margin-bottom: 30px;
  padding-left: 35px;
}

.PersonalDataSection-bestSsoFullName {
  font-weight: bold;
}

.PersonalDataSection-bestSsoPersonalData {
  flex: 1;
  font-size: 16px;
}

.PersonalDataSection-bestSsoChange {
  flex: 0 92px;
  font-size: 15px;
}

.PersonalDataSection-bestSsoSideHelpText {
  margin-top: -8px;
}

.PersonalDataSection-selectBoxContainer {
  margin-right: 0px;
}

.PersonalDataSection-agreementLink {
  text-decoration: underline;

  &.is-disabled {
    pointer-events: none;
  }
}

.PersonalDataSection-questionMark {
  top: 0;
}

.PersonalDataSection-checkbox {
  & .Checkbox-label {
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.PersonalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}

.PersonalDataSection-titleContainer {
  display: flex;
}

.PersonalDataSection-separator {
  margin-bottom: 24px;
}

.PersonalDataSection-ssoData {
  font-size: 20px;
  color: @c24-color-black;
  margin-bottom: 12px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    line-height: 0;

    svg {
      margin-right: 15px;
      position: initial;
    }
  }
}

.PersonalDataSection-agreement {
  & > div {
    align-items: flex-start;
    width: unset;
    height: unset;
  }
}

.PersonalDataSection-versicherungPlusAdvertising {
  margin: 24px 0;
  padding-bottom: 32px;
  border-block: 1px solid @c24-color-light-grey;
}
