.scroll-to-top-arrow {
  background-color: #0271c2;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
  cursor: pointer;
  height: 48px;
  padding: 20px 0px 0px 17px;
  position: fixed;
  z-index: 1000;
  bottom: 75px;
  right: 10px;
  width: 48px;
  animation: 0.2s ease 0s 1 normal none running;

  &.is-hidden {
    display: none;
  }

  &.is-app {
    bottom: calc(75px + env(safe-area-inset-bottom));
  }

  &.is-comparisonMode {
    bottom: 145px;
  }

  &.is-app.is-comparisonMode {
    bottom: calc(115px + env(safe-area-inset-bottom));
  }

  &:before{
    border: solid #fff;
    border-width: 2px 0 0 2px;
    content: " ";
    display: inline-block;
    height: 11px;
    transform: rotate(45deg);
    width: 11px;
  }
}
