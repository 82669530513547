@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.ComparisonSelectedTariffsInfo {
  display: flex;
  border-bottom: @c24-border-default;
}

.ComparisonSelectedTariffsInfo-container {
  background-color: @c24-color-white;
  margin: 0;
  padding-top: 10px;
  border-left: @c24-border-default;
  flex: 1;

  &:first-child {
    border-left: none;
  }

  &.is-resultZero {
    background-color: @c24-color-light-blue;
  }

  .ComparisonSelectedTariffsInfo-campaignBanner {
    padding: 0 10px 10px;
  }
}

.ComparisonSelectedTariffsInfo-grade {
  display: flex;
  height: 85px;
  justify-content: center;
  padding-top: 10px;

  & > .has-bottomborder {
    border-bottom: @c24-border-default;
  }
}

.ComparisonSelectedTariffsInfo-efeedbackContainer {
  display: flex;
  justify-content: center;
  min-height: 40px;
}

.ComparisonSelectedTariffsInfo-noFeedback {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  color: #cdcdcd;
}
