.c24m-page-content {
  display: none;
}

.c24m-page{
  .OverlayContainer.is-opened {
    overflow: hidden;
  }

  display: block;
}

.c24m-footer {
  margin-top: auto;
  background-color: @c24-color-white;
}

.c24m-footer-content {
  padding-bottom: calc(75px + env(safe-area-inset-bottom));
}

.c24m-footer.is-sticky {
  position: absolute;
  bottom: 0;
}

.c24m-customer {
  &.c24m-customer-guest {
    &.is-hidden {
      display: none;
    }
  }

  &.c24m-customer-user {
    &.is-hidden {
      display: none;
    }
  }

  &.c24m-customer-expired {
    &.is-hidden {
      display: none;
    }
  }
}

#c24m-navi-customer-user, #c24m-navi-customer-guest, #c24m-navi-customer-expired {
  &.is-hidden {
    display: none;
  }
}

#c24m-navi-salutation-user, #c24m-navi-salutation-guest, #c24m-navi-salutation-expired {
  &.is-hidden {
    display: none;
  }
}

#c24m-kb-logout-navi-expired, #c24m-kb-logout-navi-user {
  &.is-hidden {
    display: none;
  }
}

.c24m-search {
  &.is-hidden {
    display: none;
  }
}

.Wireframe-footerFirstInfo {
  font-size:12px;
  line-height:21px;
  cursor:pointer;
  display: inline-block;
}
