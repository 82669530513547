.PersonalDataAgreementSection {
  padding: 0 15px;
  font-size: 12px;
  margin: 24px 0 40px 0;
}

.PersonalDataAgreementSection-link {
  text-decoration: underline;
}

.PersonalDataAgreementSection-newTerms {
  padding-bottom: 15px;
  line-height: 14px;
}
