.ThankyouAppointmentBenefit-bulletContainer {
  display: flex;
  justify-content: flex-start;
}

.ThankyouAppointmentBenefit-bulletCheckIcon {
  width: 16px;
  height: 12px;
  min-width: 16px;
  fill: @c24-color-dark-blue;
  margin-right: 10px;
  margin-top: 4px;
}

.ThankyouAppointmentBenefit-bulletText {
  font-size: 16px;
  color: @c24-color-black;
}
