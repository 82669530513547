@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.TariffListItem-wrapper {
  width: 100%;

  &.is-comparison {
    min-width: 97%;

    @media @landscape {
      min-width: inherit;
    }
  }
}

.TariffListItem {
  border: @c24-border-default;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 8px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  background-color: @c24-result-tariff-list-item-background;

  &.is-extraResultZero {
    background-color: @c24-result-zero-tariff-background;
  }
}

.TariffListItem-index {
  color: @c24-color-tariff-index;
  font-size: 14px;
  line-height: 14px;
}

.TariffListItem-top {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.TariffListItem-bottom {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &.is-duv {
    display: block;
  }
}

.TariffListItem-footer {
  margin-top: 20px;
  display: flex;
}

.TariffListItem-tariffInfoBoxHeader {
  flex: 1;
  line-height: 14px;
  font-size: 14px;
  position: relative;
  color: @c24-color-dark-blue;
  display: flex;
  align-items: center;
}

.TariffListItem-linkText {
  display: flex;
  align-items: center;
  max-width: 85%;
  font-size: 16px;

  @media @small-handy {
    font-size: 14px;
  }
}

.TariffListItem-tariffInfoBoxArrow {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  fill: @c24-color-dark-blue;

  &.is-open {
    transform: rotate(180deg);
  }
}

.TariffListItem-container {
  display: flex;
}

.TariffListItem-content {
  width: 100%;
}

.TariffListItem-detailsWrapper {
  -webkit-tap-highlight-color: transparent;
}

.TariffListItem-CampaignBannerContainer {
  margin-right: 0;
  margin-left: auto;
}

.TariffListItem-bottom-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.TariffListItem-year-label {
  .CampaignBanner-container {
    margin-bottom: 10px;
    background-color: @c24-color-dark-yellow;
  }
}

.TariffListItem-header {
  display: flex;
  gap: 5px;
  margin-top: -8px;
  margin-left: -15px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
}

.TariffListItem-banners {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.TariffListItem-wishlistHeart {
  margin-left: auto;
}