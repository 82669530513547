.ConsultantCheckpoints-wrapper {
  background-color: @c24-color-white;
  border: 1px, solid, @c24-color-light-grey;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;

  &.is-comparison {
    margin: 0 15px;
    margin-bottom: 24px;
  }
}

.ConsultantCheckpoints-checkpointWrapper, .ConsultantCheckpoints-phoneNumberWrapper  {
  display: flex;
  align-items: center;
}

.ConsultantCarouselCheckpoints-checkpointText {
  flex: 9;
  font-size: 14px;
  color: @c24-color-black;
  font-family: Arial, serif;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
}

.ConsultantCarouselCheckpoints-checkpointIconContainer {
  flex: 2;
  width: 25px;
  margin-top: 5px;
  margin-left: -3%;

  @media only screen and (max-width: 375px) {
    margin-left: -30px;
  }

  @media @small-handy {
    margin-left: -50px;
  }
}

.ConsultantCarouselCheckpoints-checkpointIcon {
  height: 19.35px;
  fill: @c24-color-green;
  @media @small-handy {
    height: 20px;
  }
}

.ConsultantCheckpoints-headLine {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.ConsultantCheckpoints-phoneSvg {
  font-size: 16px;
  fill: @c24-color-dark-blue;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.ConsultantCheckpoints-serviceHotline {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.ConsultantCheckpoints-phonenNumber {
  font-size: 16px;
}

.ConsultantCheckpoints-phoneNumberWrapper {
  margin: auto;
}

.ConsultantCheckpoints-consultantInfoWrapper {
  border-bottom: 1px, solid, @c24-color-light-grey;
  margin-bottom: 10px;
  margin-top: -10px;
}
