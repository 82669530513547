@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.AppointmentPage {
  padding-top: 10px;
  background-color: @c24-color-white;
  padding-bottom: 10px;
  > div, span {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.AppointmentPage-consultantBoxWrapper {
  margin-bottom: 15px;
}
