.Spinner {
  &:after {
    content: "";
    display: inline-block;
    height: 30px;
    width: 30px;
    border-style: solid;
    border-color: @c24-color-dark-blue rgba(0, 0, 0, .3) rgba(0, 0, 0, .3) rgba(0, 0, 0, .3);
    border-radius: 50%;
    border-width: 10px;
    animation: spinAround 800ms infinite linear;
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
