@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/box-shadows';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/backgrounds-mobile';

.Button {
  display: block;
  width: 100%;
  background-color: @c24-color-button-background;
  padding: 10px;
  color: @c24-color-button-text;
  border-radius: 3px;
  font-size: 20px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: @c24-border-button;
  pointer-events: auto;
  text-align: center;
  box-shadow: @c24-box-primary-button-shadow;

  &:focus, &:active, &:active:focus, &:hover {
    outline: none;
    box-shadow: @c24-box-primary-active-button-shadow;
    text-decoration: none;
  }

  &:hover {
    background-color: @c24-color-button-hover;
  }

  &.is-disabled {
    color: @c24-color-button-disabled;
    background-color: @c24-color-button-disabled-background;
    border: 1px solid @c24-color-button-disabled-background;

    &.is-recommendation {
      height: 40px;
    }
  }

  &.Button--light {
    background-color: white;
    color: @c24-color-tariff-selection-bar-button-text;
    border: @c24-border-light-button;
    &:active, &:active:focus, &:hover, &:focus {
      background-color: @c24-color-tariff-selection-bar-button-hover-background;
      text-decoration: none;
    }
  }

  &.Button--inverted {
    background-color: @c24-color-inverted-button-background;
    color: @c24-color-light-button-text;
    border: @c24-border-inverted-button;
    font-size: 13px;
    padding: 0 6px;
    box-shadow: @c24-box-secondary-button-shadow;

    &:active, &:active:focus, &:hover, &:focus {
      outline: none;
      text-decoration: none;
      color: @c24-color-inverted-button-text;
      box-shadow: @c24-box-secondary-active-button-shadow;
      border: @c24-border-inverted-button;
    }
  }

  .Button-navigationSpinner {
    display: inline-block;
    position: relative;
    top: 0;
    height: 10px;
    margin-left: 22px;
    margin-right: -22px;
    &:after {
      height: 24px;
      width: 24px;
      margin-left: -12px;
      margin-top: -12px;
      border-width: 4px;
      content: "";
      border-color: #fff rgba(255,255,255,.4) rgba(255,255,255,.4) rgba(255,255,255,.4);
      top: 50%;
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: 50%;
      border-style: solid;
      border-radius: 50%;
      animation: spinAround .7s infinite linear;
    }


  }

  &.Button--light .Button-navigationSpinner:after {
    border-color: @c24-color-dark-blue rgba(0, 0, 0, .3) rgba(0, 0, 0, .3) rgba(0, 0, 0, .3);
  }
}
