@import 'node_modules/bu.components-common-styles/dist/styles/variables/backgrounds-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.ComparisonSelectedTariffsFeatures {
  background-color: @c24-color-white;
  padding-top: 10px;
}

.ComparisonSelectedTariffsFeatures-filterOptions {
  display: flex;
  margin: 0 10px;
  border-radius: 30px;
  background-color: #EDEDED;
  -webkit-tap-highlight-color: transparent;
}

.ComparisonSelectedTariffsFeatures-option {
  color: @c24-color-comparison-feature-filter-text;
  border-radius: 20px;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
  flex: 1;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;

  &.is-selected {
    color: @c24-color-comparison-feature-selected-filter-text;
    background-color: @c24-color-comparison-feature-selected-filter-background;
  }
}

.ComparisonSelectedTariffsFeatures-categories {
  margin: 10px 0;

  &:last-child {
    margin-bottom: 0
  }
}

.ComparisonSelectedTariffsFeatures-categoryTitle {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: @c24-border-comparison-features;
  color: @c24-color-dark-blue;
}
