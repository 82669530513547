@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/z-indexes';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/device-sizes';

.AppSplashOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: @c24-z-index-mobile-cookie-consent;
  background-size: cover;
  background-position: center;
}

.AppSplashOverlay-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
}

.AppSplashOverlay-descriptionContainer {
  position: absolute;
  bottom: 0;
  color: @c24-color-white;
  width: 100%;
}

.AppSplashOverlay-descriptionHeader {
  font-size: 20px;
  margin-bottom: 23px;

  @media @middle-handy {
    font-size: 18px;
  }
}

.AppSplashOverlay-description {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 50px;

  @media @short-handy {
    margin-bottom: 10px;
  }

  @media @middle-handy, @small-handy {
    font-size: 20px;
  }
}

.AppSplashOverlay-ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AppSplashOverlay-downloadCta {
  width: 300px !important;
  margin-bottom: 18px;
}

.AppSplashOverlay-mobileStoreLabelsContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  width: 300px;

  @media @short-handy {
    margin-bottom: 20px;
  }
}

.AppSplashOverlay-mobileStoreLabel {
  width: 120px;
}

.AppSplashOverlay-close {
  position: absolute;
  height: 60px;
  width: 60px;
  top: 0px;
  right: 0px;
  padding: 20px;
}

.AppSplashOverlay-iframe {
  position: absolute;
  visibility: hidden;
  height: 0;
  width: 0;
}