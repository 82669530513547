@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.CampaignBanner-container {
  background-color: @c24-color-salmon;
  margin-bottom: 15px;
  color: @c24-color-white;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 40px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;

  &.is-deadline {
    background-color: @c24-color-dark-yellow;
  }
}

.CampaignBanner-info {
  fill: @c24-color-white;
  height: 15px;
  width: 15px;
  flex-shrink: 0;
}

.CampaignBanner-headline {
  font-weight: 700;
}

.CampaignBanner-modalContent {
  font-size: 14px;
  color: @c24-color-dark-grey;
}
