@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.PersonalDataSection-PostalCode-input {
  display:flex;
}

.PersonalDataSection-streetInputWrapper {
  display: flex;
  justify-content: space-between;
}

.PersonalDataSection-streetInputWrapperDisabled {
  div {
    color: @c24-color-grey;
  }
}

.PersonalDataAddress-inputDisabled {
  input {
    &[disabled] {
      color: @c24-color-grey;
    }
  }
}

.PersonalDataAddress-inputWrapper {
  white-space: nowrap;
  overflow: unset;
  width: 37%;
  margin-right: 7px;
}

.PersonalDataAddress-inputWrapperCity {
  width: 63%;
}

.PersonalDataSection-streetInput {
  text-overflow: ellipsis;
  position: relative;
}

.PersonalDataSection-streetName-container {
  width: 70%;
  margin-right: 7px;
}

.PersonalDataSection-number-container {
  width: 30%;
}

.PersonalDataAddressCities-selectBox {
  padding-right: 0;
  text-overflow: ellipsis;

  &.show-asLabel {
    background: none;
    color: @c24-color-dark-grey;
  }

  &.is-disabled {
    border: none;
  }

  &:hover {
    border: none;
  }
}

.PersonalDataSection-selectBoxContainer {
  flex: 2;
}

.PersonalDataAdress-cityPlaceholder {
  font-size: 18px;
  color: @c24-color-grey;
}

.PersonalDataAddress-modalWrapper {
  white-space: initial;
}