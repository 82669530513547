@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.BackdatedPriceHint {
  background: @c24-color-dark-green;
  color: @c24-color-white;
  width: 100%;
  line-height: 34px;
  font-size: 14px;
  margin: 0px auto 10px;
  display: flex;

  position: sticky;
  top: 45px;
  z-index: 2;
}

.BackdatedPriceHintLightBulb {
  fill: @c24-color-white;
  color: @c24-color-white;
  height: 22px;
  width: 22px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.BackdatedPriceHintText {
  text-align: center;
}

.BackdatedPriceHintIcon {
  text-align: right;
  height: 15px;
  width: 15px;
  margin-top: 10px;
  margin-left:auto;
  margin-right: 5px;
  fill: @c24-color-white;
}

.BackdatedPriceHint-modalHeadline {
  color: @c24-color-headline;
  font-size: 14px;
  font-weight: 700;
  width: 95%;
  margin-bottom: 20px;
  line-height: 1.4;
  text-align: left;
}

.BackdatedPriceHint-modalContent {
  color: @c24-color-dark-grey;
  line-height: 1.4;
}
