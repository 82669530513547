.AppointmentBlacklistedCustomers-container {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  height: calc(100vh - 220px);
}

.AppointmentBlacklistedCustomers-title {
  display: flex;
  align-items: center;
  margin-bottom: 27px;

  svg {
    width: 27px;
    height: 24px;
  }

  div {
    color: @c24-color-black;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
  }
}

.AppointmentBlacklistedCustomers-informative-text {
  color: @c24-color-black;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.AppointmentBlacklistedCustomers-phone {
  display: flex;
  margin-left: 40px;

  svg {
    width: 16px;
    height: 22px;
  }
  
  div {
    :first-child {
      color: @c24-color-dark-blue;
      font-size: 20px;
      height: 24px;
      margin: 0;
    }

    :last-child {
      color: @c24-color-black;
      font-size: 14px;
      margin-top: 5px;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}

.AppointmentBlacklistedCustomers-check-info {
  color: @c24-color-black;
  font-size: 16px;
  margin-top: 30px;
}
