@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.TariffListItemPrice-tooltip {
  width: 750px;
}

.TariffListItemPrice-backdatedPriceContainer {
  display: block;
  margin-bottom: 15px;
}

.TariffListItemPrice-savingsPrice {
  color: @c24-color-black;
  font-size: 28px;
  text-align: right;
  flex-basis: auto;
}

.TariffListItemPrice-price {
  color: @c24-color-price;
  font-size: 28px;
  line-height: 22px;
  text-align: right;
  flex-basis: auto;
  white-space: nowrap;

  @media @small-handy {
    font-size: 26px;
  }
}

.TariffListItemPrice-currentPrice {
  height: 34px;
}

.TariffListItemPrice-infoContainer {
  width: 12px;
  height: 12px;
  fill: #b4b4b4;
  margin-left: 3px;
}

.TariffListItemPrice-oldPrice {
  color: @c24-color-red;
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  font-weight: 400;
  text-align: right;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row-reverse;
}

.TariffListItemPrice-priceInterval {
  font-size: 14px;
  font-weight: normal;
  float: right;
  text-align: right;
  color: @c24-color-black;
  height: 14px;
  margin-top: 3px;
}

.TariffListItemPrice-priceContainer {
  line-height: 28px;
  height: 50px;

  .CampaignBanner-container {
    width: 102px;
    background-color: @c24-color-dark-yellow;
    padding: 0px;
  }
}
