@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ComparisonSelectedTariffsFooter {
  display: flex;
}

.ComparisonSelectedTariffsFooter-inquiryButtonContainer {
  background-color: @c24-color-white;
  border-left: @c24-border-default;
  position: relative;
  flex: 1;
  padding: 10px 0;

  &:first-child {
    border-left: none;
  }
}

