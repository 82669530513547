@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.EfeedbackCommentsNextButton-button {
  background-color: @c24-color-background-grey;
  border: @c24-border-dark-blue;
  color: @c24-color-dark-blue;
  height: 40px;
  margin-top: 10px;

  &:disabled {
    color: @c24-color-light-grey;
    cursor: not-allowed;
    text-decoration: none;
  }
}

.EfeedbackCommentsNextButton-label {
  border-top: @c24-border-light-grey;
  color: @c24-color-black;
  font-size: 14px;
  margin-top: 5px;
  padding-top: 5px;
  text-align: center;
}

