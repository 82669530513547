.InsuranceLink {
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    font-size: 12px;
    color: @c24-color-dark-blue;
    line-height: 18px;
    margin-top: 24px;
    display: block;
    margin-bottom: 24px;
    text-align: left;
    width: 230px;
  }
}
