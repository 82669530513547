@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/backgrounds-mobile';

select {
  color: @c24-color-input;
  background-color: @c24-color-input-background;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;

  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /*Removes default style Firefox*/
  text-indent: 0.01px;
}
