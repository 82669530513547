@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.Tabs-tabContainer {
  top: 0;
  display: flex;
  background-color: @c24-color-white;
  box-shadow: @c24-border-box-shadow-tab;
  margin-bottom: 15px;
  position: sticky;
  z-index: 1;
}
