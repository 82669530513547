.RadioButtonSelect {
  background: @c24-color-white;
  border-bottom: @c24-border-input;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  span {
    display: flex;
    padding-block: 1.25em;
    margin-inline: 0.5em;

    &:not(:last-child) {
      border-bottom: @c24-border-light-grey;
    }

    input {
      // Keeps the radio button a circle
      flex-shrink: 0;
    }

    label {
      margin-inline-start: 0.5em;
      padding-block-start: 2px;
      font-size: 18px;
    }
  }
}
