.BenefitAmountWarning {
  position: relative;
}

.BenefitAmountWarning-container {
  margin: 10px 0 20px 0;
  font-size: 14px;
  border: @c24-border-recalc-hint;
  border: 1px solid @c24-color-black;
  padding: 15px;
  background-color: @c24-color-white;
  position: relative;
}

.BenefitAmountWarning-container:before {
  margin-right: 1px;
  position: absolute;
  top: -10px;
  left: 40px;
  height: 18px;
  width: 18px;
  background: @c24-color-white;
  content: "";
  border: 1px solid @c24-color-black;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
  z-index: 5;
}

.BenefitAmountWarning-adjustmentContainer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  color: @c24-color-dark-blue;
}

.BenefitAmountWarning-close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: @c24-color-close;
}

.BenefitAmountWarning-content {
  padding-right: 15px;
}
