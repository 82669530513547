@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.ResultSummary {
  text-align: center;
  font-size: 14px;
  color: @c24-color-black;
}

.ResultSummary-priceRange {
  color: @c24-color-price;
}
