@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.AppointmentStickyButton-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 68px;
  transition: all .5s ease 0s;
  width: 100%;
  background: @c24-color-white;
  padding: 16px;
  box-shadow: 0 0 1px transparent;

  &.is-app, &.is-separate-appointment {
    bottom: env(safe-area-inset-bottom)
  }
}
