@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.Main {
  background-color: @c24-color-white;
  color: @c24-color-black;
  font-size: 12px;
  line-height: 1.428571429; // 20/14;
  text-size-adjust: 100%;

  min-height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  * {
    box-sizing: border-box;
  }

  &.is-max-height {
    height: 100%;
  }
}

#__next {
  &.is-max-height {
    height: 100%;
  }

  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

button {
  color: inherit;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
