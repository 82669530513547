.TariffListOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;

  &.is-withTariffs {
    .TariffListOverlay-blocker {
      opacity: .7;
    }
  }
}

.TariffListOverlay-spinner {
  text-align: center;
}

.TariffListOverlay-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.TariffListOverlay-spinnerText {
  color: @c24-color-dark-blue;
  font-size: 18px;
  margin-top: 5px;
}

.TariffListOverlay-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: @c24-color-white;
}
