.ThankyouAppointmentBookingResult {
  border: 2px solid @c24-color-grey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 15px 10px;
  margin-top: 10px;
  white-space: nowrap;

  @media screen and (max-width: 375px) {
    font-size: 13px;
  }
}

.ThankyouAppointmentBookingResult-date {
  margin-left: 5px;
}
