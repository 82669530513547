.Resubscribe-container {
  margin: 32px 9px 0 9px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Resubscribe-headline {
  display: flex;
  flex: 1;
}

.Resubscribe-checkSvg {
  width: 25px;
  height: 20px;
  margin: 5px 10px 0 3px;
  fill: @c24-color-dark-green;
}

.Resubscribe-headlineText {
  font-size: 20px;
}
