@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.PersonalDataHeaderBox {
  position: relative;
  background-color: @c24-color-white;
  padding: 10px 16px 15px 16px;

  &.is-resultZero {
    padding-top: 34px;
  }
}

.PersonalDataHeaderBox-top {
  display: flex;
  align-items: flex-end;
}

.PersonalDataHeaderBox-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}

.PersonalDataHeaderBox-left {
  flex: 30;
  flex-grow: 2;
}

.PersonalDataHeaderBox-right {
  flex: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex-grow: 1;
}

.PersonalDataHeaderBox-insuranceLogo {
  max-width: 100px;
  max-height: 30px;
  margin-bottom: 10px;
}

.PersonalDataHeaderBox-tariffName {
  font-size: 14px;
  color: @c24-color-black;
}

.PersonalDataHeaderBox-price {
  font-size: 28px;
  color: @c24-color-price;
  white-space: nowrap;
}

.PersonalDataHeaderBox-paymentPeriod {
  font-size: 14px;
  color: @c24-color-black;
  margin-right: 10px;
}

.PersonalDataHeaderBox-grade {
  margin-bottom: 0;
}

.PersonalDataHeaderBox-toggleBox {
  display: flex;
  align-items: flex-start;
}

.PersonalDataHeaderBox-panelArrow {
  width: 14px;
  height: 7px;
  fill: @c24-color-dark-blue;
  margin-top: 5px;

  &.is-saving {
    margin-left: 10px;
  }

  &.is-open {
    transform: rotate(180deg);
  }
}

.PersonalDataHeaderBox-savingsPriceContainer {
  display: block;
}

.PersonalDataHeaderBox-savingsPrice {
  color: @c24-color-black;
  font-size: 28px;
}

.PersonalDataHeaderBox-savingsPriceInterval {
  line-height: 14px;
  font-size: 14px;
}

.PersonalDataHeaderBox-oldPrice {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  display: flex;
  justify-content: center;
  color: @c24-color-red;
}

.PersonalDataHeaderBox-tooltip {
  width: 750px;
}

.PersonalDataHeaderBox-infoContainer {
  height: 12px;
  width: 12px;
  fill: @c24-color-nobel-grey;
  margin-left: 3px;
  pointer-events: auto;
  cursor: help;
}

.PersonalDataHeaderBox-campaign {
  margin-top: 20px;
}
