@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/device-sizes.less';

.ComparisonSelectedTariffsHeader {
  display: flex;
  border-top: @c24-border-default;
}

.ComparisonSelectedTariffsHeader-tariffBlock {
  border-left: @c24-border-default;
  background-color: @c24-color-white;
  flex: 1;
  position: relative;
  min-width: 1px; /* This is because a bug in firefox wtih long tariffnames */

  &.is-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }

  &:first-child {
    border-left: none;
  }

  &.is-resultZero {
    background-color: @c24-color-light-blue;
  }
}

.ComparisonSelectedTariffsHeader-price {
  font-size: 22px;
  line-height: 22px;
  margin-top: 6px;
  color: @c24-color-price;
}

.ComparisonStickyHeader-priceAddition {
  font-size: 13px;
}

.ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
  margin-top: 60px;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;
}

.ComparisonSelectedTariffsHeader-wishlistHeart {
  margin-bottom: 13px;
}

.ComparisonSelectedTariffsHeader-insuranceLogo {
  display: block;
  height: 50px;
  max-width: 130px;

  &.is-compact {
    max-width: 100px;
  }
}

.ComparisonSelectedTariffsHeader-close {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: auto;
  width: 16px;
  height: 16px;
  fill: @c24-color-close;

  &.is-compact {
    width: 14px;
    height: 14px;
    top: 5px;
    right: 5px;
  }
}

.ComparisonSelectedTariffsHeader-tariffName {
  text-align: center;
  color: @c24-color-black;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 700;
  overflow: hidden;
}

.ComparisonSelectedTariffsHeader-backDatedPriceContainer,
.ComparisonSelectedTariffsHeader-priceContainer {
  color: @c24-color-black;
  text-align: center;
}

.ComparisonSelectedTariffsHeader-backDatedPriceContainer {
  margin-top: -15px;
}

.ComparisonSelectedTariffsHeader-priceContainer {
  font-size: 13px;
}

.ComparisonSelectedtariffsHeader-resultZeroContainer {
  position: absolute;
  text-align: left;
  margin-left: 10px;
  margin-right: 25px;
  margin-top: 10px;
}

.ComparisonSelectedtariffsHeader-resultZeroText {
  font-size: 12px;
  font-weight: normal;
  color: white;
  width: 97%;

  &.is-compact {
    @media @small-handy {
      font-size: 8.5px;
    }

    @media @middle-handy {
      font-size: 8.5px;
    }
  }
}

.ComparisonSelectedTariffsHeader-resultZeroHeader {
  min-width: 0;
  height: 34px;
  text-align: center;
  background-color: @c24-color-new-result-zero-header-blue;
  display: inline-block;
  width: 100px;
  vertical-align: top;
  padding: 0;

  &.is-short-text {
    height: 17px;
  }

  &.is-long-text {
    width: 110px;
  }

  &.is-compact {
    @media @small-handy {
      width: 75px;
    }

    @media @middle-handy {
      width: 75px;
    }
  }
}

.ComparisonSelectedTariffsHeader-text-suffix {
  color: @c24-result-zero-header-suffix;
  margin: 0;
}

.ComparisonSelectedTariffsHeader-resultZeroHeaderCorner {
  display: inline-block;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 34px solid @c24-color-new-result-zero-header-blue;

  &.is-short-text {
    border-top: 17px solid @c24-color-new-result-zero-header-blue;
  }
}

.ComparisonSelectedTariffsHeader-oldPrice {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  display: flex;
  justify-content: center;
  margin-top: -14px;
  color: @c24-color-red;
}

.ComparisonSelectedTariffsHeader-tooltip {
  width: 750px;
}

.ComparisonSelectedTariffsHeader-infoContainer {
  height: 12px;
  width: 12px;
  fill: #B4b4b4;
  margin-left: 3px;
  pointer-events: auto;
  cursor: help;
}
