.ResultFilterChips-selectedFiltersListContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 8px;
  margin-bottom: 10px;
}

.ResultFilterChips-filterContainer {
  background-color: @c24-color-light-blue;
  border: 1px solid @c24-color-light-grey;
  border-radius: 3px;
  color: @c24-color-black;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 10px;
  height: 40px;
  box-sizing: content-box;
  font-size: 14px;
  box-shadow: @c24-box-filter-shadow;
}

.ResultFilterChips-unselectFilterButton {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  fill: @c24-color-dark-blue;
}

.ResultFilterChips-unselectAllFiltersButton {
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: @c24-color-dark-blue;
  &:hover {
    text-decoration: underline;
  }
}

