@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.ThankyouAppointmentsCalendar-error {
  font-size: 14px;
  color: @c24-color-red;
  display: block;
}

.ThankyouAppointmentsCalendar-cancellationButton {
  margin: 10px auto auto;
}
