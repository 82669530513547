.ResultComparisonTariffList-tariffItemContainer {
  display: flex;
}

.ResultComparisonTariffList-checkboxContainer {
  margin-top: 2px;
}

.ResultComparisonTariffList {
  padding: 8px 10px 20px;
  overflow: hidden;
}

.ResultComparisonTariffList-singleRow {
  position: relative;
  margin-bottom: 14px;
}

.ResultComparisonTariffList-comparisonBanner {
  margin: 0 0 10px;
  padding: 5px 5px 5px 0;
  font-size: 16px;
}

.ResultComparisonTariffList-checkbox {
  -webkit-tap-highlight-color: transparent;

  width: 40px;
  height: 100%;

  & .Checkbox-label {
    align-self: flex-end;
  }

  & .Checkbox-icon {
    width: 20px;
    height: 20px;
    margin-top: 0;
    flex: none;
  }
}
