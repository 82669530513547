.FractionOfficeWorkPage {
  margin-top: 16px;
}

.FractionOfficeWorkPage-link {
  font-size: 12px;
  color: @c24-color-dark-blue;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
}

.FractionOfficeWorkPage-ctaButton {
  margin-top: 16px;
}
