@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/device-sizes';

.AppPromotion {
  justify-content: center;
  margin-bottom: 20px;
  position: fixed;
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 75px;
  display: flex;
}

.AppPromotion-button {
  background-color: @c24-color-malaga-blue;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
  height: 40px;
  border: 1px solid @c24-color-dark-blue;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: @c24-color-white;
}

.AppPromotion-labelContainer {
  border-right: 1px solid @c24-color-dark-blue;
  column-gap: 6px;
  display: flex;
  padding: 8px 16px;

  span {
    color: @c24-color-white;
    text-decoration: none;
  }
}

.AppPromotion-DownloadIcon,
.AppPromotion-closeIcon {
  font-size: inherit;
  justify-content: center;
  overflow: hidden;
  vertical-align: initial;
  stroke-width: 0;
  display: inline-flex;
  fill: @c24-color-white;
}

.AppPromotion-DownloadIcon{
  width: 18px;
}

.AppPromotion-closeIcon {
  width: 12px;

}

.AppPromotion-closeIconContainer {
  align-items: center;
  display: flex;
  font-size: 12px;
  vertical-align: middle;
  padding: 8px 12px 8px 10px;
}