.ThankyouAppointmentBenefits-headline {
  font-size: 18px;
}

.ThankyouAppointmentBenefits-container, .ThankyouAppointmentBenefits-bulletsContainer {
  display: flex;
  flex-direction: column;
}

.ThankyouAppointmentBenefits-container {
  margin-bottom: 10px;
}

.ThankyouAppointmentBenefits-bulletsContainer {
  padding-top: 10px;
  >div {
    margin-bottom: 10px;
  }
  :last-child{
    margin-bottom: 0px;
  }
}
