@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.KnownCustomerConsultantBox {
  margin-bottom: 10px;
}

.KnownCustomerConsultantBox-headlineText {
  font-size: 20px;
  color: @c24-color-black;
}

.KnownCustomerConsultantBox-box {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: center;

  .KnownCustomerConsultantBox-photo {
    border-radius: 47px;
    width: 95px;
    height: 95px;
  }
  img {
    border: solid 1px @c24-color-light-grey;
  }
}

.KnownCustomerConsultantBox-info {
  display: flex;
  gap: 9px;
  flex-direction: column;
}

.KnownCustomerConsultantBox-name {
  font-size: 16px;
  color: @c24-color-black;
}

.KnownCustomerConsultantBox-experience {
  font-size: 12px;
  color: @c24-color-malaga-grey;
}

.KnownCustomerConsultantBox-phone {
  display: flex;
  align-items: center;

  font-size: 12px;
  color: @c24-color-dark-blue;

  .KnownCustomerConsultantBox-phone-icon {
    width: 20px;
    height: 20px;
    fill: @c24-color-dark-blue;
  }
}

.KnownCustomerConsultantBox-ctaButton {
  margin-top: 24px;
}