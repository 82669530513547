@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/box-shadows';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ThankyouAppointmentConfirmation {
  margin-bottom: 10px;
}

.ThankyouAppointmentConfirmation-headlineContainer {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-family: Arial;
  color: @c24-color-black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.ThankyouAppointmentConfirmation-greenCheckmark {
  width: 25px;
  min-width: 25px;
  height: 20px;
  margin-top: 2px;
  margin-right: 10px;
  fill: @c24-color-green;

  &.is-mailConfirmation {
    margin-top: 0;
  }
}

.ThankyouAppointmentConfirmation-infoContainer {
  margin-top: 15px;
  color: @c24-color-black;
  font-size: 16px;
  line-height: 20px;
}

.ThankyouAppointmentConfirmation-textarea {
  color: @c24-color-dark-grey;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Verdana;
  border: @c24-border-light-grey;
  background-color: @c24-color-white;
  background-size: cover;
  height: 100px;
  width: 100%;
  resize: none;
  background-clip: padding-box; // removes ios shadow

  &.is-error {
    border: @c24-border-error;
    margin-bottom: 0;
  }
}

.ThankyouAppointmentConfirmation-bottomButton {
  margin-bottom: 15px;
}

.ThankyouAppointmentConfirmation-mailContainer {
  .ThankyouAppointmentConfirmation-infoContainer;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.ThankyouAppointmentConfirmation-timeSpan {
  white-space: nowrap;
}

.ThankyouAppointmentConfirmation-emailErrorText {
  font-size: 14px;
  color: @c24-color-red;
  margin-bottom: 15px;
}

.ThankyouAppointmentConfirmation-thirdViewButton {
  background-color: @c24-color-white;
  color: @c24-color-malaga-grey;
  border: 1px solid @c24-color-very-light-grey;
  box-shadow: @c24-box-secondary-button-shadow;

  &:active, &:active:focus, &:hover, &:focus  {
    background-color: @c24-color-background-light-grey;
    box-shadow: @c24-box-secondary-active-button-shadow;
  }
}
