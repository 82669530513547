.SalutationBirthday-title {
  font-size: 24px;
  color: @c24-color-black;
  line-height: 28px;
  margin-top: 44px;
  margin-bottom: 16px;
}

.SalutationBirthday-link {
  font-size: 12px;
  color: @c24-color-dark-blue;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
