.ExpandArrow-container {
  display: flex;
  color: #005ea8;
}

.ExpandArrow-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  font-family: Arial;
  font-size: 12px;
  fill: #005ea8;
  font-weight: 400;
  text-decoration: none solid rgb(0, 94, 168);

  &.is-open {
    transform: rotate(180deg);
  }
}
