.TransparencyBox {
  margin: 10px;
  padding: 10px 15px 10px 15px;
  background-color: @c24-color-light-grey;
  color: @c24-color-black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

}

.TransparencyBox-close {
  width: 16px;
  height: 16px;
  margin-top: 3px;
  cursor: pointer;
  fill: @c24-color-nobel-grey;

}
