.AppointmentConsultantBox-container {
  height: 100%;
  background-color: @c24-color-white;
}

.AppointmentConsultantBox-headline {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.AppointmentConsultantBox-subHeadline {
  font-size: 16px;
  margin-top: 15px;
}

.AppointmentConsultantBox-personalHeadline {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.AppointmentConsultantBox-experience {
  text-align: center;
  font-size: 12px;
}

.AppointmentConsultantBox-name {
  text-align: center;
  font-size: 16px;
  color: @c24-color-dark-grey;
  margin-top: 6px;
}

.AppointmentConsultantBox-photoAndQuote {
  display: flex;
  justify-content: space-between;
}

.AppointmentConsultantBox-photoContainer {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AppointmentConsultantBox-photo {

  &.is-substituteHint {
    height: 57px;
    width: 57px;
    margin-left: 5px;
  }
}

.AppointmentConsultantBox-quote {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #dcdcdc;
  background-color: @c24-color-light-blue;
  background-size: cover;
  padding: 15px;
  font-size: 14px;

  .Spinner {
    margin: auto;
  }
}

.AppointmentConsultantBox-arrow {
  width: 25px;
  height: 25px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: @c24-color-light-blue;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: 35px;
  left: -14px;
}

.AppointmentConsultantBox-text {
  word-wrap: break-word;
  width: auto;
  hyphens: auto;
  word-break: break-word;
}

.AppointmentConsultantBox-time {
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 14px;
}
