.ReinforcementElement {
  margin-top: 5px;
  display: flex;
}

.ReinforcementElement-thumb {
  height: 28px;
  fill: @c24-color-green;

  &--birthday {
    width: 54px;
  }

  &--benefit-age-limit {
    width: 35px;
  }
}

.ReinforcementElement-message {
  margin-left: 10px;
  color: @c24-color-green;
  font-size: 14px;
}
