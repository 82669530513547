@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.OnboardingPersonalData-nextButton {
  height: 50px;
  margin-bottom: 20px;
}

.OnboardingPersonalData-content {
  display: block;
  padding: 15px 15px 0;
  position: relative;
  border-top: @c24-border-light-grey;

  .OnboardingPersonalData-campaignBanner {
    margin-top: 20px;
  }
}

.OnboardingPersonalData-header {
  font-size: 20px;
  margin-bottom: 10px;
  color: @c24-color-malaga-grey;
}

.OnboardingPersonalData-ConsultantCheckpoints {
  margin-top: 24px;
}
