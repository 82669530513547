@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.ThankyouPage > .CseTeasers {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.CseTeasers-headline {
  font-size: 18px;
  font-weight: 400;
  color: @c24-color-headline;
  margin: 0 0 10px 0;
}

.CseTeasers-container {
  background-color: #fff;
  margin: 0 -14px;
  padding: 0;
  font-size: 11px;
  line-height: 16px;
  color: #666;

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
}
