.ConsultantBox-container {
  height: 100%;
  position: relative;
  border: @c24-border-light-grey;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  stroke-width: 1;
  margin: 25px 0;
  padding: 10px 0;
}

.ConsultantBox-headerText {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.ConsultantBox-content {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
}

.ConsultantBox-photoContainer {
  padding-right: 5px;
}

.ConsultantBox-photo {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid @c24-color-light-grey;
  margin: auto;
}

.ConsultantBox-consultantDetails {
  justify-content: center;
  margin: 2px auto;
}

.ConsultantBox-name {
  text-align: center;
  font-size: 16px;
  color: @c24-color-black;
}

.ConsultantBox-companyName {
  text-align: center;
  font-size: 12px;
  color: @c24-color-black;
  margin-top: 5px;
}

.ConsultantBox-phoneNumber {
  font-size: 14px;
  color: @c24-color-dark-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.ConsultantBox-phoneSvg {
  fill: @c24-color-icon-default;
  width: 27px;
  height: 27px;
}

.ConsultantBox-appointmentButton {
  margin-top: 10px;
}
