@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ChipsSelect-chip {
  color: @c24-color-black;
  background-color: @c24-color-white;
  height: 30px;
  border-radius: 23px;
  border: @c24-border-width solid @c24-color-light-grey;
  margin: 3px;
  padding: 8px 15px;
  font-size: 13px;
  line-height: 13px;
  min-width: 64px;

  &.is-selected {
    border-color: @c24-color-dark-blue;
    background: @c24-color-light-blue;
  }

  &:hover {
    border-color: @c24-color-dark-blue;
  }
}

.ChipsSelect-label {
  font-size: 18px;
  color: @c24-color-black;
  line-height: 20px;
  margin-bottom: 16px;
  margin-right: 40px;
}

.ChipsSelect-chipsWrapper {
  margin-right: 40px;
  margin-left: -3px;
}
