.FirstViewFilterChips {
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 5px;
  min-height: 28px;
  box-sizing: content-box;
  font-size: 14px;
  background-color: @c24-color-white;
  border: solid 1px @c24-color-light-grey;
  word-break: break-word;

  @media @small-handy {
    font-size: 13px;
  }

  &.is-selected {
    background-color: @c24-color-light-blue;
    color: @c24-color-black;
  }

  &.is-disabled {
    color: @c24-color-nobel-grey;
    border-color: @c24-color-light-grey;
    background-color: #fafafa;
    box-shadow: none;
  }
}

.FirstViewFilterChips-unselectFilterButton {
  height: 12px;
  width: 12px;
  margin-left: 10px;
  fill: @c24-color-dark-blue;
  flex-shrink: 0;
}

.FirstViewFilterChips-fromPriceText {
  font-size: 12px;
  min-width: 40px;
  color: @c24-color-grey;
  margin-left: 10px;
  margin-right: 5px;
  white-space: nowrap;
  &.is-unselect-icon-shown {
    margin-right: 0;
  }

  &.is-long-text {
    @media @small-handy {
      margin-left: 0;
    }
    @media @middle-handy {
      margin-left: 0;
    }
  }
}

.FirstViewFilterChips-contentContainer {
  &.is-long-text {
    @media @small-handy {
      display: flex;
      flex-direction: column;
      max-width: 210px;
    }
    @media @middle-handy {
      display: flex;
      flex-direction: column;
    }
  }
}
