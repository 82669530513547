@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.OnboardingProgressBar {
  display: flex;
  justify-content: space-between;
  background-color: @c24-color-white;
  align-items: center;
  padding: 15px 30px;
}

.OnboardingProgressBar-step {
  color: @c24-color-nobel-grey;
  flex: 1;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 26px;
  height: 58px;

  &.OnboardingProgressBar-step--is-active {
    color: @c24-color-dark-blue;
  }

  &.OnboardingProgressBar-step--is-completed {
    color: @c24-color-malaga-grey;
  }
}

.OnboardingProgressBar-step svg {
  height: 35px;
  width: 35px;
  display: block;
  margin-bottom: 6px;

  fill: @c24-color-nobel-grey;
}

.OnboardingProgressBar-step--is-active svg, .OnboardingProgressBar-step--is-completed svg {
  fill: @c24-color-dark-blue;
}

.OnboardingProgressBar-stepSeparator {
  position: relative;
  width: 100%;
  border: @c24-border-nobel-grey;
  top: -10px;

  &.OnboardingProgressBar-stepSeparator--is-completed {
    border: @c24-border-dark-blue;
  }
}

.OnboardingProgressBar-step--is-active:after {
  position: absolute;
  bottom: -25px;
  height: 18px;
  width: 18px;
  background: @c24-color-white;
  content: '';
  border: @c24-border-light-grey;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
  z-index: 5;
}

.OnboardingProgressBar-stepLabel--inputs {
  position: absolute;
  top: 32px;
  left: -11px;
}

.OnboardingProgressBar-stepLabel--features {
  position: absolute;
  top: 32px;
  left: -22px;
}

.OnboardingProgressBar-stepLabel--reductions {
  position: absolute;
  top: 32px;
  left: -8px;
}

.OnboardingProgressBar-stepLabel--comparison {
  position: absolute;
  top: 32px;
  left: -11px;
}
