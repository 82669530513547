@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.CashbackThankyouPage {
  margin: 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;

  .CashbackThankyouPage-row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 16px;
    color: @c24-color-black;
    font-weight: 400;
    line-height: 30px;
  }

  .CashbackThankyouPage-svg {
    fill: @c24-color-checked;
    height: 20px;
    width: 25px;
    margin-top: 6px;
  }

  .CashbackThankyouPage-header {
    font-size: 20px;
    color: @c24-color-black;
  }

  .CashbackThankyouPage-body {
    font-size: 16px;
    padding-left: 36px;
    color: @c24-color-black;
  }
}
