@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';

.ThankyouInfo {
  margin-bottom: 10px;
  font-size: 16px;
}

.ThankyouInfo-headline {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.ThankyouInfo-checkSvg {
  width: 28px;
  height: 22px;
  margin-right: 15px;
  fill: @c24-color-checked;
}

.ThankyouInfo-headlineText {
  color: @c24-color-black;
  font-size: 20px;
}

.ThankyouInfo-emailConfirmation {
  margin-bottom: 20px;
  word-wrap: break-word;
}

.ThankyouInfo-tariffInfo {
  margin-bottom: 25px;
}

.ThankyouInfo-tariffInfoRow {
  display: flex;
}

.ThankyouInfo-tariffInfoLabel {
  width: 125px;
}

.ThankyouInfo-tariffInfoDescription {
  font-weight: 700;
}
