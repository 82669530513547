.JobSituation-jobSituations {
  margin-top: 10px;
}

.JobSituation-title {
  font-size: 24px;
  color: @c24-color-black;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
}
