@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/device-sizes.less';

.TuevLogo {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

.TuevLogo-svg {
  max-width: 100%;
  height: 55px;
  pointer-events: auto;
}

.TuevLogo-popupTuevBadge {
  width: 165px;
  height: 90px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: inline-block;
}

.TuevLogo-popupContent {
  color: @c24-color-black;
}

.TuevLogo-popupHeadline {
  font-weight: bold;
}

.TuevLogo-popupSubHeadline {
  font-weight: bold;
  color: @c24-color-headline;
  margin-bottom: 10px;
}

.TuevLogo-popupAddressHeadline {
  margin-top: 10px;
  padding: 8px 0;
  border-top: 1px solid @c24-color-grey;
  font-weight: bold;
}
