@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders.less';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.RolandPromotionBanner-PersonalPageBannerContainer {
  margin-top: 10px;
}

.RolandPromotionBanner-Container {
  background-color: @c24-color-white;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: @c24-border-default;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.RolandPromotionBanner-BannerBody {
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  white-space: nowrap;
}

.RolandPromotionBanner-bannerCorner {
  position: absolute;
  left: 100%;
  top: 0;
  border-right: 10px solid transparent;
  border-top: 21px solid @c24-color-green;
}

.RolandPromotionBanner-BannerText {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0px 5px;
  & > span {
    margin-top: 1px
  }
}

.RolandPromotionBanner-infoIcon {
  fill: @c24-color-white;
  height: 13px;
  width: 13px;
}

.RolandPromotionBanner-TextContainer {
  display: flex;
  flex: 6;
  flex-direction: column;
  justify-content: center;
  padding-top: 35px;
  white-space: nowrap;
  margin-left: 15px;
}

.RolandPromotionBanner-Text {
  color: @c24-color-grey;
  margin-top: -2px;
}

.RolandPromotionBanner-Text, .RolandPromotionBanner-Title {
  @media @small-handy {
    font-size: 13px;
  }
}

.RolandPromotionBanner-Title {
  font-size: 16px;
  font-weight: bold;
  color: @c24-color-black;
  @media @small-handy {
    font-size: 13px;
  }
}

.RolandPromotionBanner-Logo {
  flex: 1;
  margin-top: 28px;
  margin-left: 14px;

  & > svg {
    width: 26px;
    height: 35px;
    & >.cls-1 {
      fill: @c24-color-malaga-blue;
    }
  }
}

.RolandPromotionBanner-SignetContainer {
  flex: 1;
  & > svg {
    height: 76px;
    width: 76px;
    padding: 10px;

    @media @small-handy {
      height: 85px;
      width: 63px;
      margin-left: -6px;
    }
  }
}

.RolandPromotionBanner-modalContent {
  white-space: normal;
}

.RolandPromotionBannerModal-helpTextTitle {
  font-weight: 700;
  color: @c24-color-new-dark-blue;
}