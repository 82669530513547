.OnboardingCta {
  position: sticky;
  z-index: 2;
  display: flex;
  bottom: calc(10px + env(safe-area-inset-bottom));
}

.OnboardingCta-button {
  height: 50px;
}
