.ComparisonDetails-feature {
  border-bottom: @c24-border-comparison-features;
  display: flex;
  min-height: 40px;
  position: relative;
}

.ComparisonDetails-featureTitle {
  min-height: 40px;
  position: relative;
  background-color: #EDEDED;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}

.ComparisonDetails-featureTitleText {
  padding: 5px 25px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  color: @c24-color-black;
  font-size: 14px;
}

.ComparisonDetails-tariffFeature {
  background-color: @c24-color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-left: @c24-border-comparison-features;
  flex: 1;
  text-align: center;
  padding: 5px;

  > div > svg {
    margin-top: 6px;
  }

  &:first-child {
    border-left: none;
  }
}

.ComparisonDetails-helpText {
  right: 13px;
  top: 10px;
}
