@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.Cashback-spinner {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.CashbackForm {
  margin: 20px;
  color: @c24-color-black;
  font-weight: 400;
}

.CashbackForm-greetings-container {
  margin-bottom: 16px;
}

.CashbackForm-container {
  margin-top: 16px;
}

.CashbackForm-top-header {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
}

.CashbackForm-sub-header {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 16px;
}

.CashbackForm-greetings {
  color: @c24-color-black;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 0;
}

.CashbackForm-info {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 0;
}

.CashbackForm-separator {
  border: 0;
  border-bottom: 1px solid @c24-color-light-grey;
  margin: 0;
}

.CashbackForm-form-item {
  font-size: 16px;
  margin-bottom: 16px;
}

.CashbackForm-input {
  width: 100%;
  margin-bottom: 10px;
}

.CashbackForm-bankDetails {
  width: 100%;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin: 4px 0 0;
  color: @c24-color-dark-grey;
  font-size: 14px;
}

.CashbackForm-ibanInputBankDetails {
  margin-bottom: 0px;
}

.CashbackForm-footer {
  font-size: 13px;
  color: @c24-color-dark-grey;
  line-height: 20px;

  .CashbackForm-footer-link {
    margin-left: 4px;
    text-decoration: underline;
  }
}

.CashbackForm-cta {
  width: 100%;
  margin-top: 20px;

  button {
    width: 100%;
  }
}
