@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/backgrounds-mobile';

.Checkbox {
  font-size: 14px;
  display: flex;
  width: 100%;
  border: 0;
  outline: @c24-color-button-outline;
  padding-left: 0;
  text-align: left;

  &.is-multiline {
    width: 100%;
    .Checkbox-icon {
      margin-top: 3px;
    }
  }

  &.has-help-text {
    width: 80%;
  }

  &.is-invalid {
    & .Checkbox-icon--off {
      fill: @c24-color-dark-red;
    }
  }
}

.Checkbox-button {
  display: flex;
  width: 100%;
  border: 0;
  outline: @c24-color-button-outline;
  padding-left: 0;
  text-align: left;
}

.Checkbox-icon {
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 4px;
  background-color: @c24-color-light-button-background;
  border-radius: 3px;

  &.Checkbox-icon--off {
    fill: @c24-color-checkbox-unchecked;
  }

  &.Checkbox-icon--on {
    fill: @c24-color-checkbox-checked;

    &.is-disabled {
      fill: @c24-color-checkbox-unchecked;
    }
  }

  &.is-disabled {
    background-color: @c24-color-checkbox-disabled;
  }
}

.Checkbox-label {
  font-size: 16px;
}
