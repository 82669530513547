@import "node_modules/bu.components-common-styles/dist/styles/variables/colors";

.RadioButton-input {
  margin: 0px;
  flex-shrink: 0;
}

.RadioButton-container {
  display: flex;
  gap: 10px;
}
