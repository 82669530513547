@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.ResultTab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @c24-color-black;
  background-color: @c24-color-white;
  font-size: 14px;
  border-bottom: @c24-border-content-box;
  height: 45px;

  padding: 5px 0;
  text-align: center;

  &.is-middle {
    justify-content: space-between;
  }

  &.is-middle::after {
    content: "";
    height: 24px;
    width: 1px;
    border-left: 1px solid @c24-color-light-grey;
    margin-right: -1px;
  }

  &.is-middle::before {
    content: "";
    height: 24px;
    width: 1px;
    border-right: 1px solid @c24-color-light-grey;
    margin-left: -1px;
  }
}

.ResultTab-icon {
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  fill: @c24-color-black;

  &.is-dot-visible::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 8px;
    width: 8px;
    right: 0;
    border-radius: 50%;
    background: @c24-color-dark-red;
  }
}

.ResultTab-comparisonIcon {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  fill: @c24-color-black;
}

.ResultTab-sortIconContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-items: center;
}

.ResultTab-sortingTextWrapper {
  text-align: start;
  display: grid;
}

.ResultTab-sortingText{
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ResultTab-sortingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
}
