@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.OnboardingAdditionalDataSection-label {
  font-size: 14px;
  margin-top: 10px;
  color: @c24-color-malaga-grey;
  line-height: 14px;
  margin-bottom: 7px;
}

.OnboardingAdditionalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}

.OnboardingAdditionalDataSection-chipsInputHelpText {
  top: 1px;
}

.OnboardingAdditionalDataSection {
  margin-bottom: 24px;

  > div {
    margin-bottom: 15px;
    position: relative;
  }
}