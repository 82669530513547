.TariffInfoBox {
  border: @c24-border-default;
  border-top: none;
  padding-left: 15px;
  padding-right: 15px;
  background-color: @c24-color-white;

  &.is-detailsPage {
    padding: 0;
    border: none;
  }
}

.TariffInfoBox-container {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: @c24-border-default;

  &.is-last {
    border: none;
  }
}

.TariffInfoBox-featureIcon {
  margin-right: 6px;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.TariffInfoBox-feature {
  display: flex;
}

.TariffInfoBox-featureText, .TariffInfoBox-customerRatingsText {
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: @c24-color-black;

  @media @small-handy {
    font-size: 14px;
  }
}

.TariffInfoBox-customerRatingsText {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 350px) {
    display: block;
  }

  .TariffInfoBox-ratingStarsContainer {
    display: flex;
    font-size: 14px;

    .EfeedbackRatingStars {
      margin-top: 2px;
    }
  }
}

.TariffInfoBox-featureHelpText {
  font-size: 14px;
  color: @c24-color-black;
}

.TariffInfoBox-promotionHelpText {
  margin-left: 26px;
  font-size: 14px;
  color: @c24-color-black;
}

.TariffInfoBox-promotionHelpTextHeadline {
  font-weight: bold;
  margin-left: 26px;
  font-size: 14px;
  color: @c24-color-black;
}

.TariffInfoBox-promotionName {
  background-color: @c24-color-promotion-hint;
  color: @c24-color-promotion-name;
  padding: 0 3px;
  height: 20px;
  font-size: 15px;
  border-radius: 2px;
  margin-right: 6px;
}

.TariffInfoBox-promotionDescription {
  font-size: 16px;
  margin-bottom: 10px;
  color: @c24-color-promotion-description;
}

.TariffInfoBox-promotionDescriptionDuv{
  font-size: 14px;
  margin-bottom: 10px;
  color: @c24-color-promotion-description;
}

.TariffInfoBox-feature, .TariffInfoBox-promotion {
  display: flex;
  position: relative;
}

.TariffInfoBox-closeContainer {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  padding-right: 20px;
  position: relative;
  padding-left: 10px;
  color: @c24-color-dark-blue;
  display: flex;
  align-items: center;
}

.TariffInfoBox-arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  right: 5px;
  top: 5px;
  fill: @c24-color-dark-blue;
}
