@import "node_modules/bu.components-common-styles/dist/styles/variables/colors";

.RadioButtonGroup {
  border: 1px solid @c24-color-light-grey;
  border-radius: 5px;
  padding: 16px;

  .RadioButton-container {
   border-bottom: solid 1px @c24-color-lighter-grey;
   margin-bottom: 16px;
  }

  .RadioButton-container:last-child {
    border-bottom: none;
     margin-bottom: 0px;
  }
}
