@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.OnboardingCoverageDataSection-row {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.OnboardingCoverageDataSection-label {
  font-size: 14px;
  margin-top: 10px;
  color: @c24-color-malaga-grey;
  line-height: 14px;
  margin-bottom: 7px;
}

.OnboardingCoverageDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}
