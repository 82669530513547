@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.TariffListItemBullets-featureTooltip, .TariffListItemBullets-promotionTooltip {
  width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.TariffListItemBullets-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}

.TariffListItemBullets-container {
  font-size: 12px;
  margin-right: 10px;

  &.PersonalDataHeaderBox-bulletsContainer {
    padding-bottom: 0;
  }

  &.is-comparison {
    @media @small-handy {
      width: 180px;
    }
  }
}

.TariffListItemBullets-feature {
  display: flex;
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.TariffListItemBullets-promotion {
  margin-top: 5px;
  display: flex;
  position: relative;
}

.TariffListItemBullets-featureIcon {
  margin-right: 6px;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.TariffListItemBullets-promotionName {
  background-color: @c24-color-promotion-hint;
  color: @c24-color-promotion-name;
  padding: 0 3px;
  height: 16px;
  font-size: 11px;
  border-radius: 2px;
  margin-right: 6px;
}

.TariffListItemBullets-promotionDescription {
  color: @c24-color-promotion-description;
}

.ResultTariffListItemBullets-labelWrapper {
  display: flex;
  margin-top: 5px;
}

.ResultTariffListItemBullets-labelWrapperDuv {
  display: flex;
  margin-top: 10px;
}

.ResultTariffListItemBullets-label {
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 15px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 20px;
  line-height: 19px;

  @media @small-handy {
    font-size: 12px;
  }
}

.ResultTariffListItemBullets-labelComment {
  font-size: 16px;
  line-height: 19px;
  padding-left: 6px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media @small-handy {
    font-size: 14px;
  }
}

.ResultTariffListItemBullets-labelCommentDuv {
  font-size: 14px;
  line-height: 19px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media @small-handy {
    font-size: 12px;
  }
}

.TariffListItemBullets-featureText {
  font-size: 16px;

  @media @small-handy {
    font-size: 14px;
  }
}

.TariffListItemBullets-bottom {
  position: relative;
  margin-right: -10px;
}

.TariffListItemBullets-pointsIcon {
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
}