.ThankyouAppointment {
  margin-bottom: 10px;
}

.ThankyouAppointment-description {
  font-size: 18px;
  margin-bottom: 10px;
}

.ThankyouAppointment-timeslotNotAvailableHeaderContainer {
  display: flex;
  align-items: center;
}

.ThankyouAppointment-modalText {
  font-size: 16px;
  color: @c24-color-dark-grey;
  line-height: 20px;
  margin-bottom: 15px;
}

.ThankyouAppointment-modalHeader {
  font-size: 16px;
  color: @c24-color-black;
  font-weight: 700;
  margin-bottom: 5px;
}

.ThankyouAppointment-timeslotNotAvailableHeaderText {
  font-size: 16px;
  color: @c24-color-black;
  font-weight: 700;
  margin-bottom: 5px;
}

.ThankyouAppointment-expiredModalButtons {
  display: flex;
  justify-content: space-between;
}

.ThankyouAppointment-callButton {
  display: flex;
  align-items: center;
}

.ThankyouAppointment-modalBackButton {
  width: 50%;
}

.ThankyouAppointment-phoneSvg {
  font-size: 16px;
  fill: @c24-color-dark-blue;
  width: 15px;
  height: 22px;
  margin-right: 5px;

  @media @small-handy {
    display: none;
  }
}