@import "node_modules/bu.components-common-styles/dist/styles/variables/colors";

.AcceptText-QuestionMark {
  fill: @c24-color-nobel-grey;
}

.AcceptText-row {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  .AcceptText-text {
    font-size: 16px;
    color: @c24-color-black;
  }

  .AcceptText-feature {
    font-size: 14px;
  }
}
