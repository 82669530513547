@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';

.StickyButton-container {
  box-shadow: 0 1px 4px rgb(@c24-color-black,0.3);
  width: 100%;
  background: @c24-color-background-grey;
  position: fixed;
  left: 0;
  bottom: 62px;
  transition: all .5s ease 0s;
  z-index: 2;

  &.is-app {
    bottom: env(safe-area-inset-bottom);
  }
}

.StickyButton-button {
  margin: 10px;
  width: 95%;
}
