@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/borders';

.OnboardingSummary {
  .Page;

  display: block;
  padding: 5px 15px 15px 15px;
  position: relative;
  border-top: @c24-border-light-grey;

  .OnboardingCoverageDataSection-row, .OnboardingPersonalDataSection-space, .OnboardingAdditionalDataSection {
    margin-bottom: 16px;
  }
}

.OnboardingSummary-sectionHeader {
  padding-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-top: @c24-border-nobel-grey;
}

.OnboardingSummary-sectionContent {

  > div {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
    color: @c24-color-malaga-grey;
  }
}

.OnboardingSummary-sectionHeader.OnboardingSummary-sectionHeader--hasNoBorder {
  border: none;
}

.OnboardingSummary-sectionHeaderTitle {
  font-size: 24px;
  line-height: 28px;
  color: @c24-color-dark-blue;
}

.OnboardingSummary-sectionHeaderIcon {
  margin-top: 2px;
  width: 20px;
  height: 20px;
}

.OnboardingSummary-header {
  font-size: 20px;
  color: @c24-color-dark-blue;
}

.OnboardingSummary-actionButton {
  height: 50px;
  position: sticky;
  bottom: calc(10px + env(safe-area-inset-bottom));
  z-index: 2;
}

.OnboardingSummary-sectionContentWithSpace {
  margin-bottom: 24px;
}