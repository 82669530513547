@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile';
@import 'node_modules/bu.components-common-styles/dist/styles/variables/device-sizes.less';

.EkomiLogo {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

.EkomiLogo-svg {
  pointer-events: auto;
  max-width: 100%;
  height: 58px;
}

.EkomiLogo-popupEkomiBadge {
  position: fixed;
  right: -17px;
  bottom: 5px;
  display: inline-block;
  width: 240px;
  height: 80px;
}

.EkomiLogo-popupHeadline {
  font-weight: bold;
}

.EkomiLogo-popupSubHeadline {
  font-weight: bold;
  color: @c24-color-black;
  margin-bottom: 10px;
}

.EkomiLogo-contentPart {
  margin-bottom: 10px;
}

.EkomiLogo-popupAddressHeadline {
  padding: 8px 0;
  border-top: 1px solid @c24-color-grey;
  font-weight: bold;
}

.EkomiLogo-popupContent {
  color: @c24-color-black;
}
