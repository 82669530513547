@import 'node_modules/bu.components-common-styles/dist/styles/variables/colors-mobile.less';

.EfeedbackCommentsHeader {
  color: @c24-color-dark-blue;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}


